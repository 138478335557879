// extracted by mini-css-extract-plugin
export var carouselContainer = "Q_w7 d_w d_H d_bf d_Z";
export var carouselContainerCards = "Q_w8 Q_w7 d_w d_H d_bf d_Z";
export var carouselContainerSides = "Q_w9 d_w d_H d_Z";
export var prevCarouselItem = "Q_xb d_w d_H d_0 d_k";
export var prevCarouselItemL = "Q_xc Q_xb d_w d_H d_0 d_k";
export var moveInFromLeft = "Q_xd";
export var prevCarouselItemR = "Q_xf Q_xb d_w d_H d_0 d_k";
export var moveInFromRight = "Q_xg";
export var selectedCarouselItem = "Q_xh d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "Q_xj Q_xh d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "Q_xk Q_xh d_w d_H d_Z d_bf";
export var nextCarouselItem = "Q_xl d_w d_H d_0 d_k";
export var nextCarouselItemL = "Q_xm Q_xl d_w d_H d_0 d_k";
export var nextCarouselItemR = "Q_xn Q_xl d_w d_H d_0 d_k";
export var arrowContainer = "Q_xp d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "Q_xq Q_xp d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "Q_xr Q_xq Q_xp d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "Q_xs Q_xp d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "Q_xt";
export var nextArrowContainerHidden = "Q_xv Q_xs Q_xp d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "Q_kG d_0";
export var prevArrow = "Q_xw Q_kG d_0";
export var nextArrow = "Q_xx Q_kG d_0";
export var carouselIndicatorContainer = "Q_xy d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "Q_xz d_w d_bz d_bF";
export var carouselText = "Q_xB d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "Q_xC Q_xB d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "Q_xD d_b7";
export var carouselIndicator = "Q_xF Q_xD d_b7";
export var carouselIndicatorSelected = "Q_xG Q_xD d_b7";
export var arrowsContainerTopRight = "Q_xH d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "Q_xJ d_0 d_bl d_bC";
export var arrowsContainerSides = "Q_xK d_0 d_bl d_bC";
export var smallArrowsBase = "Q_xL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "Q_xM Q_xL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "Q_xN Q_xM Q_xL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "Q_xP Q_xL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "Q_xQ Q_xP Q_xL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "Q_n9";
export var iconSmall = "Q_xR";
export var multipleWrapper = "Q_xS d_bC d_bF d_bf";
export var multipleImage = "Q_xT d_bC";
export var sidesPrevContainer = "Q_xV Q_xM Q_xL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "Q_xW Q_xM Q_xL d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";