// extracted by mini-css-extract-plugin
export var noPadding = "K_fj d_fj";
export var videoFrameWrapper = "K_wn d_kS d_b5";
export var videoIframeStyle = "K_nS d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "K_wp d_kR d_bK";
export var videoInnerWrapper = "K_wq d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "K_wr d_dw d_w";
export var videoInnerInnerWrapper = "K_ws d_bz d_bD d_bJ";
export var btnWrapper = "K_d2 d_w d_bz d_bD d_cj";
export var btn = "K_mB d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n C_t2 C_tQ";
export var icon = "K_n9 d_cC";
export var textLeft = "K_dv";
export var textCenter = "K_dw";
export var textRight = "K_dx";