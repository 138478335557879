// extracted by mini-css-extract-plugin
export var socialContainer = "G_vT";
export var instagramLink = "G_vV";
export var socialWrapperLeft = "G_qz d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "G_vW";
export var socialContentContainerFull = "G_vX";
export var instagramContainer = "G_vY";
export var twitterContainer = "G_vZ";
export var facebookContainer = "G_v0";
export var socialErrorContainer = "G_v1";
export var facebookContainerWide = "G_v2";
export var twitterContainerWide = "G_v3";
export var socialParagraphCenter = "G_v4";
export var instaWrapper = "G_v5";
export var SubtitleSmall = "G_pd C_pd C_tl C_tx";
export var SubtitleNormal = "G_pf C_pf C_tl C_ty";
export var SubtitleLarge = "G_pg C_pg C_tl C_tz";
export var textLeft = "G_dv";
export var textCenter = "G_dw";
export var textRight = "G_dx";