// extracted by mini-css-extract-plugin
export var alignLeft = "J_rd d_fp d_bG d_dv";
export var alignCenter = "J_bP d_fq d_bD d_dw";
export var alignRight = "J_rf d_fr d_bH d_dx";
export var testimonialsContainer = "J_wg d_dW";
export var testimonialsContainerFull = "J_wh d_dT";
export var testimonialsMainHeader = "J_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "J_jy d_jy";
export var testimonialsComponentSmall = "J_jx d_jx";
export var testimonialsComponentsWrapper = "J_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "J_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "J_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "J_wj d_d7";
export var colEqualHeight = "J_wk d_bz";
export var testimonialsColumnWrapper = "J_jz d_jz d_b3";
export var testimonialsImageWrapper = "J_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "J_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "J_jr d_jr d_Z d_bz";
export var design2TextWrapper = "J_vS d_bC d_bP d_bJ";
export var design3 = "J_wl d_cD d_H";
export var imageDesign2 = "J_wm";
export var SmallSmall = "J_tZ C_tZ C_tl C_tJ";
export var SmallNormal = "J_t0 C_t0 C_tl C_tK";
export var SmallLarge = "J_t1 C_t1 C_tl C_tG";
export var exceptionWeight = "J_sK C_tN";