// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "H_v6 d_bD";
export var storyRowWrapper = "H_hx d_hx d_bK";
export var storyLeftWrapper = "H_v7 d_bz d_bP";
export var storyWrapperFull = "H_v8 d_cD";
export var storyWrapperFullLeft = "H_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "H_mv d_hy";
export var storyLeftWrapperCenter = "H_v9 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "H_wb d_hF";
export var storyHeader = "H_wc d_hD d_w d_cs";
export var storyHeaderCenter = "H_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "H_hB d_hB d_by d_dw";
export var storyBtnWrapper = "H_wd d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "H_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "H_rj d_fg d_Z";
export var imageWrapperFull = "H_wf d_w d_H d_bf d_Z";
export var SubtitleSmall = "H_pd C_pd C_tl C_tx";
export var SubtitleNormal = "H_pf C_pf C_tl C_ty";
export var SubtitleLarge = "H_pg C_pg C_tl C_tz";
export var textLeft = "H_dv";
export var textCenter = "H_dw";
export var textRight = "H_dx";