// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_sY d_gv d_cs";
export var heroHeaderCenter = "z_gw d_gw d_cs d_dw";
export var heroHeaderRight = "z_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "z_sZ d_gr d_cw";
export var heroParagraphCenter = "z_gs d_gs d_cw d_dw";
export var heroParagraphRight = "z_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "z_s0 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "z_s1 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "z_s2 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "z_s3 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "z_s4 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "z_s5 C_s5";
export var heroExceptionNormal = "z_s6 C_s6";
export var heroExceptionLarge = "z_s7 C_s7";
export var Title1Small = "z_s8 C_s8 C_tl C_tm";
export var Title1Normal = "z_s9 C_s9 C_tl C_tn";
export var Title1Large = "z_tb C_tb C_tl C_tp";
export var BodySmall = "z_tc C_tc C_tl C_tF";
export var BodyNormal = "z_td C_td C_tl C_tG";
export var BodyLarge = "z_tf C_tf C_tl C_tH";